import React, { useContext } from 'react';
import { MenuContext } from '../../../context'

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data'

const Article = () => {
  const menuContext = useContext(MenuContext);

  return (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
      <p>With effect from Monday 19th April 2021 we will be making the following changes to our mortgage product range:</p>
      <p><strong>Summary of changes</strong></p>
      <p>We are participating in the Government backed Mortgage Guarantee Scheme (MGS) which means that 95% LTV Mortgages will now be available for new business purchase cases where the schemes qualification criteria have been met. These rates should be selected from the Mortgage Guarantee Scheme (MGS) range found within the Mortgage Rate Sheet.</p>
      <p>All our fixed rate end dates will be extended as below:</p>
      <ul>
        <li>2 year fixed rates - 31st August 2023</li>
        <li>5 year fixed rates - 31st August 2026</li>
      </ul>
      <p>Our 95% LTV range, outside of the Government backed Mortgage Guarantee Scheme (MGS), is still only available for existing HSBC UK mortgage customers switching rates and not new business. These rates should be selected from the 95% LTV range found within the Mortgage Rate Sheet clearly labelled as 'Rate Switch Only'.</p>
      <p>An updated mortgage rate sheet will be published on Monday 19th April 2021 to reflect these changes.</p>
      <p>You can browse the range of HSBC mortgages using our <a href="/products/product-finder.html" inert={menuContext.isMenuOpen ? "true" : null}>product finder</a> or by downloading our <a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href} inert={menuContext.isMenuOpen ? "true" : null}>latest rate sheet<span className="accessible">&nbsp;This PDF opens in a new window</span></a>.</p>
    </NewsArticle>
  )
}

export default Article
